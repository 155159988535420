import React from "react"
import {MDXProvider} from '@mdx-js/react'
import '../styles/tailwind.css'

import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';

import Title from '../components/Title'
import Text from '../components/Text'
import Heading from '../components/Heading'
import ListItem, {List} from '../components/ListItem'
import {TaskListIcon} from '@primer/octicons-react'
import Center from '../components/Center'
import Quote from '../components/Quote'


const Layout = ({children, className}) => {

  const components = {
    h1: Title,
    h2: Heading,
    li: ListItem,
    p: Text,
    ul: List,
    blockquote: Quote,
    task: TaskListIcon,
  }
  deckDeckGoHighlightElement({ languages: ['html','css'] } );
  return (
    <MDXProvider components={components}>
      <div className={`container mx-auto max-w-xl my-10 ${className}`}>
        {children}
      </div>
    </MDXProvider>
  )
}

export default Layout
